import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">© 2024 Koi health care app</p>
    </div>
  );
}

export default Footer;
